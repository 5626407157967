import React from 'react';
import Controls from "./Controls";

export default function ({question, next, previous, surveyAnswers, setSurveyAnswers}) {
  const { id, title, options } = question;

  const isChecked = ({id: answerId}) => surveyAnswers[id]?.includes(answerId) || false;

  const toggleAnswer = ({id: answerId}) => {
    const answers = surveyAnswers[id] || [];
    const newAnswers = answers.includes(answerId) ?
      answers.filter(a => a !== answerId)  :
      answers.concat(answerId)

    setSurveyAnswers({
      ...surveyAnswers,
      [id]: newAnswers,
    });
  };

  return <div className="max-w-lg mx-auto mt-5">
    <h3 className="text-xl text-center">{ title }</h3>
    <fieldset className="mt-5 mb-5">
      {
        options.map(option =>
          <div key={option.id} className="flex items-center mb-4">
            <input id={option.id}
                   type="checkbox"
                   name={id}
                   value={option.id}
                   className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                   checked={isChecked(option)}
                   onChange={() => toggleAnswer(option)}
                   aria-labelledby={option.id}
                   aria-describedby={option.id} />
            <label htmlFor={option.id} className="font-medium text-gray-900 ml-2 mb-0 block">
              {option.text}
            </label>
          </div>
        )
      }
    </fieldset>
    <Controls next={next} previous={previous} />
  </div>
}