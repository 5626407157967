import React from "react";
import PropTypes from "prop-types";
import trackEvent from '../utils/track';
import Logo from "../../assets/images/dutyteam-logo-full.svg"
import ClientSurvey from './questions/ClientSurvey';

class Survey extends React.Component {
  componentDidMount() {
    trackEvent('onSurveyPage');
  }

  render () {
    return (
      <div className="max-w-lg mx-auto px-2">
        <a className="flex" href="/">
          <div className="flex">
            <img src={Logo} width={250} />
          </div>
        </a>
        <ClientSurvey />
      </div>
    );
  }
}

Survey.propTypes = {
    greeting: PropTypes.string
};

export default Survey;
