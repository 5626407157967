import React from 'react';
import { useOnce } from '../../utils/hooks';
import trackEvent from "../../utils/track";

export default (Component) => ({ question, next, previous, surveyAnswers, setSurveyAnswers }) => {
  const { id } = question;

  useOnce(() => {
    trackEvent("onQuestion", { id });
  });

  return <div>
    <question.banner />
    <Component
        question={question}
        surveyAnswers={surveyAnswers}
        setSurveyAnswers={setSurveyAnswers}
        next={next}
        previous={previous} />
  </div>;
}