import React from 'react';
import trackEvent from "../../utils/track";
import { useOnce } from "../../utils/hooks";

export default function ({ question, surveyAnswers, setSurveyAnswers }) {
  const { title } = question;

  useOnce(() => {
    trackEvent("OnSubmitClientSurvey", surveyAnswers);
  });

  return <div className="max-w-xl mx-auto mt-5">
    <h3 className="text-xl text-center">{ title }</h3>
    <div className="text-lg">
      We received your information and we will contact you within 24h.
    </div>
  </div>
}