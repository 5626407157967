import React, { useState } from "react";
import { HashRouter, Redirect, Route } from 'react-router-dom';
import Question from "./Question";

export default function Survey({ questions, seedAnswers = {} }) {
  const [surveyAnswers, setSurveyAnswers] = useState({...seedAnswers});

  return (
    <HashRouter>
      <Route exact path="/">
        <Redirect to={questions[0].id} />
      </Route>
      {
        questions.map((question, index) =>
          <Route key={question.id} path={`/${question.id}`}>
            <Question
              question={question}
              surveyAnswers={surveyAnswers}
              setSurveyAnswers={setSurveyAnswers}
              previous={index > 0 && questions[index - 1].id}
              next={index + 1 < questions.length && questions[index + 1].id}
            />
          </Route>
        )
      }
    </HashRouter>
  );
}