import React from 'react';
import { Link } from 'react-router-dom';

export default function ({ next, previous}) {
  return <div className={`flex items-center justify-between ${next && 'flex-row-reverse'} mb-4`}>
      { next && <Link to={next}>
        <button
          className="flex text-blue-500 border border-solid border-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 font-bold uppercase text-base px-8 py-3 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150">
          <span>Next</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
          </svg>
        </button>
      </Link> }
      { previous && <Link to={previous}>
        <button
          className="no-underline flex text-blue-500 border border-solid border-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 font-bold uppercase text-base px-8 py-3 rounded-l outline-none focus:outline-none mb-1 ease-linear transition-all duration-150">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
          </svg>
          <span>Previous</span>
        </button>
      </Link> }
    </div>
}