import React from 'react';
import Controls from "./Controls";

export default function ({question, next, previous, surveyAnswers, setSurveyAnswers}) {
  const { id, title } = question;

  const surveyAnswer = surveyAnswers[id] || {};
  const { name, email } = surveyAnswer;

  const setAnswer = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    setSurveyAnswers({
      ...surveyAnswers,
      [id]: {
        ...surveyAnswer,
        [field]: value,
      }
    });
  };

  return <div className="max-w-lg mx-auto mt-5">
    <h3 className="text-xl text-center">{ title }</h3>
    <fieldset className="mt-5 mb-5">
      <div className="items-center mb-4">
        <input
          id="name"
          type="text"
          name="name"
          placeholder="Full Name"
          value={name}
          onChange={(e) => setAnswer(e)}
          className="w-full rounded-lg p-4 border text-gray-800 border-gray-200 bg-white"
        />
      </div>

      <div className="items-center mb-4">
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setAnswer(e)}
          className="w-full rounded-lg p-4 border text-gray-800 border-gray-200 bg-white"
        />
      </div>
    </fieldset>
    <Controls next={next} previous={previous} />
  </div>
}