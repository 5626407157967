import React from 'react';
import Survey from "./Survey";
import trackEvent from "../../utils/track";
import { useOnce } from "../../utils/hooks";
import RadioQuestion from "./RadioQuestion";
import CheckboxQuestion from "./CheckboxQuestion";
import ContactQuestion from "./ContactQuestion";
import ThankYouConfirmation from "./ThankYouConfirmation";
import QuestionWithBanner from "./QuestionWithBanner";

const PROJECT_LENGTH = {
  id: "project-length",
  title: "How long do you need the developer?",
  banner: () => <div className="mt-5 bg-blue-100 border border-blue-500 px-4 py-3" role="alert">
    <p className="font-bold text-gray-800"> Thanks for your interest in hiring through DutyTeam!</p>
    <p className="text-sm">By answering these questions, you'll help us find your perfect match!</p>
  </div>,
  component: QuestionWithBanner(RadioQuestion),
  options: [{
      id: "lessThan1Week",
      text: "Less than 1 week",
  }, {
    id: "1To3Months",
    text: "1 to 3 months",
  }, {
    id: "3To6Months",
      text: "3 to 6 months",
  }, {
    id: "longerThan6Months",
      text: "Longer than 6 months",
  }, {
    id: "decideLater",
      text: "I'll decide later",
  }],
}

const PROJECT_TYPE = {
  id: "project-type",
  title: "What type of project are you hiring for?",
  component: RadioQuestion,
  options: [{
    id: "newIdea",
    text: "New idea or project",
  }, {
    id: "existingProject",
    text: "Existing project that needs more resources",
  }, {
    id: "ongoingAssistance",
  text: "Ongoing assistance or consultation",
  }, {
    id: "none",
    text: "None of the above, I'm just looking to learn more about DutyTeam",
  }],
}

const COMMITMENT = {
  id: "commitment",
  title: "What level of time commitment will you require from the developer?",
  component: RadioQuestion,
  options: [{
    id: "fullTime",
    text: "Full time (40 or more hrs/week)",
  }, {
    id: "partTime",
    text: "Part time (Less than 40 hrs/week)",
  }, {
    id: "hourly",
    text: "Hourly",
  }, {
    id: "decideLater",
    text: "I'll decide later",
  }],
};

const SKILLS = {
  id: "skills",
  title: "What skills would you like to see in your new hire?",
  component: CheckboxQuestion,
  options: [{
    id: "rubyOnRails",
    text: "Ruby on Rails",
  }, {
    id: "react",
    text: "React",
  }, {
    id: "reactNative",
    text: "React Native",
  }, {
    id: "nodeJs",
    text: "NodeJS",
  }, {
    id: "python",
    text: "Python",
  }, {
    id: "other",
    text: "Other",
  }],
}

const PROJECT_START = {
  id: "project-start",
  title: "When do you need the developer to start?",
  component: RadioQuestion,
  options: [{
    id: "immediately",
    text: "Immediately",
  }, {
    id: "in1To2Weeks",
    text: "In 1 to 2 weeks",
  }, {
    id: "inMoreThan2Weeks",
    text: "More than 2 weeks from now",
  }, {
    id: "decideLater",
    text: "I'll decide later",
  }],
}

const REMOTE = {
  id: "remote",
  title: "Are you open to working with a remote developer?",
  component: RadioQuestion,
  options: [{
    id: "yes",
    text: "Yes",
  }, {
    id: "no",
    text: "No",
  }, {
    id: "notSure",
    text: "I'm not sure",
  }, {
    id: "decideLater",
    text: "I'll decide later",
  }],
};

const EXPERTISE = {
  id: "expertise",
  title: "What level of team contribution are you looking from the developer?",
  component: RadioQuestion,
  options: [{
    id: "entryLevel",
    text: "Entry Level",
  }, {
    id: "junior",
    text: "Junior Individual Contributor",
  }, {
    id: "senior",
    text: "Senior Individual Contributor",
  }, {
    id: "midManager",
    text: "Mid-Level Management",
  }, {
    id: "executive",
    text: "Executive / Senior Leadership",
  }],
}

const COMPANY_SIZE = {
  id: "company-size",
  title: "How many people are employed at your company?",
  component: RadioQuestion,
  options: [{
    id: "lessThan10",
    text: "Less than 10",
  }, {
    id: "11To50",
    text: "11 - 50",
  }, {
    id: "51To200",
    text: "51 - 200",
  }, {
    id: "201To1000",
    text: "201 - 1000",
  }, {
    id: "1001To5000",
    text: "1001 - 5000",
  }, {
    id: "moreThan5000",
    text: "More than 5000",
  }],
}

const BUDGET = {
  id: "budget",
  component: RadioQuestion,
  title: "Our developers hourly rates range from $40 - $120 USD. Does your budget align with these hourly rates?",
  options: [{
    id: "tes",
    text: "Yes",
  }, {
    id: "no",
    text: "No",
  }],
}

const CONTACT = {
  id: "contact",
  component: ContactQuestion,
  title: "What is your contact information?",
}

const THANK_YOU = {
  id: "thank-you",
  component: ThankYouConfirmation,
  title: "Thank you for your interest!",
}

export default function ClientSurvey() {
  useOnce(() => {
    trackEvent('onClientSurveyPage');
  });

  return <Survey questions={[
      PROJECT_LENGTH,
      PROJECT_TYPE,
      COMMITMENT,
      SKILLS,
      PROJECT_START,
      REMOTE,
      EXPERTISE,
      COMPANY_SIZE,
      BUDGET,
      CONTACT,
      THANK_YOU,
  ]} />;
}


